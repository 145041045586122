<template>
  <header
    v-show="!$route.meta.hideHeader"
  >
    <Spacer
      v-if=" $route.path !== '/'"
      :y="{default: 'xl', m: 'l'}"
    >
      <Flex justify="center">
        <router-link
          to="/"
          class="logo"
        >
          <img
            src="~@/assets/img/logo.svg"
            alt=""
          >
        </router-link>
      </Flex>
    </Spacer>
    <!-- <Spacer
      v-if=" $route.path === '/'"
      y="l"
      x="l"
      class="menu"
    >
      <Menu />
    </Spacer> -->
  </header>
</template>

<script>
// import Menu from '@/components/ui/menu';
// Un comment for WC
// import WcMenu from '@/components/wc/wc-menu';

export default {
  name: 'Header',
  components: {
    // Menu,
    // Un comment for WC
    // WcMenu,
  },
};
</script>

<style lang="scss" scoped>
.logo{
  width: 120px;
}
.menu{
  position: absolute;
  left: 0;
  text-align: center;
  width: 100%;
  top: 60px;
  z-index: 1000;
  @include mq(m){
    width: fit-content;
  text-align: left;
  top: auto;
  left: auto;
  bottom: 0;
  right: 0;
  }

}
.spacer{
  padding-bottom: 20px;
}
</style>
