var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,{tag:"component",class:[
    'flex' ].concat( _vm.direction,
    _vm.flexNoWrap,
    _vm.flexJustify,
    _vm.flexAlign,
    [_vm.gap ? 'flex-gap' : false],
    [_vm.rowGap && _vm.rowGap !== '' ? ("row-gap-" + _vm.rowGap) : false],
    [_vm.columnGap && _vm.columnGap !== '' ? ("column-gap-" + _vm.columnGap) : false] )},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }