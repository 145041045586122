var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"}},[_c('Flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],class:['modal', ("modal-theme--" + _vm.theme), 'overlay'],attrs:{"align":"center","tabindex":_vm.show ? 0 : -1}},[(_vm.data)?_c('Wrapper',{class:['modal-wrapper'],attrs:{"size":"m","boxed":""}},[(!_vm.data.mandatory)?_c('Icon',{staticClass:"icon--close",attrs:{"name":"close","fn":_vm.close,"theme":"minimal","size":"m"}}):_vm._e(),(_vm.data.type === 'video')?[_c('Video',{ref:"video",attrs:{"data":{
            embed: true,
            url: _vm.data.url,
          }}})]:_vm._e(),(_vm.data.type === 'content')?[_c('Spacer',{attrs:{"all":"m"}},[_c('Content',{attrs:{"data":{
              title: _vm.data.content.title,
              rich_text: _vm.data.content.richtext,
              cta: _vm.data.content.cta,
            }}})],1)]:_vm._e(),(_vm.data.type === 'dialog')?[_c('Spacer',{attrs:{"all":"m"}},[_c('Grid',{attrs:{"col":"12"}},[_c('Title',{attrs:{"data":{
                value: _vm.data.content.title,
              }}}),_c('Richtext',{attrs:{"data":{
                value: _vm.data.content.richtext,
              }}}),_c('Flex',{attrs:{"justify":"center"}},[_c('Cta',{attrs:{"data":{
                  fn: _vm.dialogFn,
                  title: _vm.data.content.cta.title,
                },"theme":"button"}})],1)],1)],1)]:_vm._e()],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }