<template>
  <div id="app">
    <AppHeader />
    <PageMenu :key="$route.path" />
    <transition
      :css="false"
      appear
      mode="out-in"
      @enter="enter"
      @leave="leave"
    >
      <router-view :key="$route.path" />
    </transition>
    <AppFooter />

    <Modal />
    <Snackbar />
    <!--
      Un comment for WC
      <Cart />
    -->
    <Cookie v-if="options && options.cookies" />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import medusa from '@/assets/js/observer';
import lazyload from '@/mixins/lazyload';

import AppHeader from '@/components/ui/header';
import AppFooter from '@/components/ui/footer';
import PageMenu from '@/components/ui/page-menu';

import Modal from '@/components/ui/modal';
import Snackbar from '@/components/ui/snackbar';
import Cookie from '@/components/ui/cookie';

// Un comment for WC
// import Cart from '@/components/wc/cart';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    Modal,
    Cookie,
    Snackbar,
    PageMenu,
    // Un comment for WC
    // Cart,
  },
  mixins: [lazyload],
  computed: {
    ...mapGetters(['currentPost', 'options']),
  },
  created() {
    medusa.init();
  },
  mounted() {
    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    window.addEventListener('resize', debounce(() => {
      this.$bus.$emit('windowResized');
      Vue.set(Vue.prototype, '$mq', this.$mq.setMq());
      this.$mq.vh();
    }, 400));
  },
  methods: {
    enter(el, done) {
      this.$store.commit('SET_TRANSITION', false);
      done();
    },
    leave(el, done) {
      this.$store.commit('SET_TRANSITION', true);
      done();
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';

.app-loaded {
  #loader {
    display: none;
  }
}

</style>
