<template>
  <footer
    v-show="showFooter"
    class="page-footer"
  >
    <div
      v-if="options && footer && footer.description"
      v-html="footer.description"
    />
    <Grid v-if="options && footer && footer.information">
      <div
        v-for="(info, indexInfo) in footer.information"
        :key="indexInfo"
        :class="info.section_class"
      >
        <div
          v-for="(item, index) in info.section"
          :key="index"
        >
          <Flex>
            <Label
              v-if="item.label"
              :data="item.label"
            />
            <Richtext
              v-if="item.content"
              :data="item.content"
            />
            <Cta
              v-if="item.content"
              :data="item.content"
            />
          </Flex>
        </div>
      </div>
    </Grid>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';

import Label from '@/components/typo/label';
import Richtext from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';

export default {
  name: 'Footer',
  components: {
    Label,
    Richtext,
    Cta,
  },
  computed: {
    ...mapGetters(['options', 'currentPost']),
    showFooter() {
      if (this.$route.meta.hideFooter) {
        return false;
      }
      if (!this.$route.meta.customView && !this.currentPost) {
        return false;
      }
      return true;
    },
    footer() {
      const { footer } = this.options;
      return footer;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
