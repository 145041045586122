var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Grid',{attrs:{"col":"12"}},[_c('router-link',{attrs:{"to":_vm.data.link || _vm.data.permalink}},[(_vm.data.gds_featured_image)?_c('Media',{staticClass:"media",attrs:{"data":{
        type: 'image',
        image: _vm.data.gds_featured_image,
        ratio: false,
        object_fit: 'cover',
      }}}):_vm._e(),_c('Title',{attrs:{"data":{
        value: _vm.data.taxonomy || _vm.data.name ? _vm.data.name : _vm.data.title.rendered,
      }}})],1),_c('div',[_c('Price',{attrs:{"product":_vm.data}}),_c('Availability',{attrs:{"product":_vm.data}})],1),_c('Flex',{attrs:{"justify":"center"}},[(!_vm.hasVariations)?_c('Cta',{attrs:{"theme":"button","data":{
        title: _vm.$labels.add_to_cart,
        url: false,
        fn: _vm.addToCart,
      },"disabled":!_vm.addableToCart}}):_c('Cta',{attrs:{"theme":"button","data":{
        title: _vm.$labels.select_option,
        url: _vm.data.link || _vm.data.permalink,
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }