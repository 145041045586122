<template>
  <Spacer x="m">
    <div class="line" />

    <Title
      v-if="data.title"
      :data="{ value: data.title }"
    />
    <Grid
      :col="gridCol"
      :template="gridTemplate"
    >
      <div
        v-for="(item, index) in data.items"
        :key="index"
        class="item"
      >
        <Content
          v-if="checkContent(item)"
          :data="setContent(item)"
        />
        <!-- <Media
          v-if="checkMedia(item)"
          :data="setMedia(item)"
        /> -->
      </div>
    </Grid>
  </Spacer>
</template>

<script>
import {
  setContent,
  setMedia,
  checkContent,
  checkMedia,
} from '@/assets/js/utils';
import Title from '@/components/typo/title';
import Content from '@/components/blocks/content';
// import Media from '@/components/blocks/media';

export default {
  name: 'Repeater',
  components: {
    Content,
    // Media,
    Title,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      setContent,
      setMedia,
      checkContent,
      checkMedia,
    };
  },
  computed: {
    gridCol() {
      let cols = null;
      const elementsLength = this.data.items?.length
        || this.data.categories?.length
        || this.data.posts?.length;
      let defaultColsNumber = 3;
      if (elementsLength) {
        if (elementsLength < 5) {
          defaultColsNumber = 12 / elementsLength;
        } else {
          defaultColsNumber = 3;
        }
      }
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_size } = layout;
          cols = {
            ...cols,
            [media_query]:
              layout_size === 'auto'
                ? defaultColsNumber.toString()
                : layout_size,
          };
        });
      } else {
        cols = {
          xs: 12,
          s: 6,
          m: defaultColsNumber.toString(),
        };
      }
      return cols;
    },
    gridTemplate() {
      let template = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_type } = layout;
          template = {
            ...template,
            [media_query]: layout_type,
          };
        });
      } else {
        template = 'grid';
      }
      return template;
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  // margin-top: 60px;
  // padding-top: 40px;
  // border-top: 1px solid white;
  color: white;
  // text-align: center;
  @extend %typo--xl;
}

.line{
    // padding-top: 40px;
  border-top: 1px solid white;
    // margin-bottom: 40px;
}

.line+.title{
  padding-top: 0;
  margin-top: 0;
  margin-top: 30px;
  // margin-bottom: 40px;

}
.grid{
  margin-top: 30px;
  // background: red;
}
</style>
